<bui-modal-layout class="updates-modal" textAlign="side">
  <bui-modal-header>
    <bui-title element="h1">New updates</bui-title>
    <bui-button icon="close" (click)="closeModal()"></bui-button>
  </bui-modal-header>
  <bui-modal-content>
    <ng-container *ngIf="(updates$ | async).length; else loading">
      <updates-article
        *ngFor="let update of updates$ | async"
        [update]="update"
      ></updates-article>
    </ng-container>
    <ng-template #loading>
      <div class="modal-loader">
        <rmd-loader></rmd-loader>
      </div>
    </ng-template>
  </bui-modal-content>
  <bui-modal-actions>
    <span (click)="navigateAndClose('updates')">
      Click here to see all updates
    </span>
  </bui-modal-actions>
</bui-modal-layout>
