import { Component, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from '../../../../_core/root-store/root-reducers'
import { userActions } from '../../../../_core/root-store/user/user.actions'
import { BuiModalRef } from '@fleet-customer/booster-ui'
import { Update } from '../../../../_core/models/update.models'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'

@Component({
  selector: 'updates-modal',
  templateUrl: './updates-modal.component.html',
  styleUrls: ['./updates-modal.component.scss'],
})
export class UpdatesModalComponent implements OnDestroy {
  public updates$: Observable<Update[]>

  constructor(
    private store: Store<AppState>,
    private modalRef: BuiModalRef<UpdatesModalComponent>,
    private router: Router
  ) {
    this.updates$ = this.store
      .select((state) => state.user.updates)
      .pipe(map((updates) => updates.slice(0, 1)))
  }

  ngOnDestroy(): void {
    this.store.dispatch(userActions.CHANGE_LAST_SEEN_VERSION())
  }

  public navigateAndClose(routerLink: string): void {
    this.router.navigate([`/${routerLink}`])
    this.closeModal()
  }

  public closeModal(): void {
    this.modalRef.close()
  }
}
