import { Component, Input } from '@angular/core'
import { Update } from '../../../../_core/models/update.models'

@Component({
  selector: 'updates-article',
  templateUrl: './updates-article.component.html',
  styleUrls: ['./updates-article.component.scss'],
})
export class UpdatesArticleComponent {
  @Input() update: Update
}
