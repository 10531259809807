<rmd-card>
  <article class="update-article">
    <div class="article-header">
      <h5 class="article-header__date">
        {{ update.releaseDate }}
        <div *ngIf="update.isNew" class="tag">
          <span>NEW</span>
        </div>
      </h5>
      <h3 class="article-header__label">
        {{ update.releaseLabel }}
      </h3>
    </div>

    <div class="update-article__body" [innerHTML]="update.bodyHtml"></div>

    <div class="update-article__image">
      <img
        *ngFor="let img of update.images"
        [src]="img"
        [alt]="update.releaseLabel"
      />
    </div>
  </article>
</rmd-card>
